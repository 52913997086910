export const environment = {
  project: 'webapp',
  production: true,

  // Environment variables
  envDomain: "integration",
  COOKIE_LOCALE_DOMAIN: "inte.netatmo.com",
  LEGALS_URL: "https://legals.inte.netatmo.com/",

  appUrl: "https://home.inte.netatmo.com/",
  apiUrl: "https://app.inte.netatmo.com/api/",
  syncApiUrl: "https://app.inte.netatmo.com/syncapi/v1/",
  authUrl: "https://auth.inte.netatmo.com/",
  logoutUrl: "https://auth.inte.netatmo.com/logout",
  cookiePrefix: "intenetatmocom",
  cookieDomain: ".inte.netatmo.com",
  helpcenterUrl: "https://helpcenter.netatmo.com/hc/",
  settingsLegrandUrl: "https://settings.inte.netatmo.com/legrand/#/",
  settingsEnergyUrl: "https://settings.inte.netatmo.com/energy/",
  settingsWeatherUrl: "https://settings.inte.netatmo.com/weather/",
  settingsAircareUrl: "https://settings.inte.netatmo.com/aircare/",
  myUrl: "https://my.inte.netatmo.com",
  myAccountUrl: "https://myconnectedaccount.inte.netatmo.com/",
  langsUrl: "/langs/",
  termsAndCondtionsUrl: "https://view.inte.netatmo.com/",
  legalsUrl: "https://legals.inte.netatmo.com/",
  env: "inte",

  webRTCSocketUrl: "wss://app-ws.inte.netatmo.com/appws/",
  webPusherUrl: "wss://app-ws.inte.netatmo.com/ws/",

  turnUrl: "https://app-turn.inte.netatmo.com/api/",
  weatherMapUrl: "https://weathermap.inte.netatmo.com",
  shopUrl : "https://shop.netatmo.com/",

  staticBlobUrl: 'https://nawebstaticinte.blob.core.windows.net/public/',
  contentUrl: 'https://content.inte.netatmo.com/',

};
